.productCard {
  position: relative;
  width: 18rem;
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #ffffff;
  margin: 1rem;
  transition: box-shadow 0.3s ease;
}

/* .productCard:hover {
  transform: translateY(-5px);
} */
/* hover icon  */
.iconContainer {
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

/* rating  */
.rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.rating div p {
  color: #71717a;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.ratingStar {
  display: flex;
  align-items: center;
}
.ratingStar img {
  width: 20px;
}

.iconContainer button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.iconContainer button:hover {
  transform: scale(1.1);
}

.iconContainer button img {
  width: 25px;
  margin-top: 4px;
  height: 18px;
}

.productCard:hover .iconContainer {
  opacity: 1;
}

.productCardTag {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 5px;
  color: white;
  background-color: #ee4143;
  font-size: 13px;
  padding: 1px 8px;
  border-radius: 20px;
}
.productCardTag img {
  width: 15px;
}
.productImageContainer {
  border-bottom: 1px solid #eaeaea;
  height: 300px;
  /* padding: 10px; */
}

.productImageContainer img {
  width: 100%;
  height: 100%;
}

.productInfo {
  padding: 10px;
  text-align: start;
}

.productInfo .productCategory {
  color: #9596a1;
  font-size: 15px;
}
.productInfo .stockCategory {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.productInfo .productStock {
  font-size: 14px;
  border-radius: 20px;
}
.productInfo .productStock .InStock {
  color: #49944d;
}
.productInfo .productStock .OutStock {
  color: #ee4143;
}
.productInfo .productName {
  font-size: 19px;
  font-weight: 600;
}

.productInfoPriceCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.productInfoPrice {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 3px 0 10px 0;
}
.productInfoPrice div:nth-child(1) {
  font-size: 22.5px;
  font-weight: bold;
  color: rgb(28, 85, 95);
}
.productInfoPrice div:nth-child(2) {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: line-through;
  color: #767676;
}

.productInfoPriceCartBtn button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  outline: none;
  width: 100%;
  font-size: 15.2px;
  background-color: rgb(28, 85, 95);
  /* background-color: rgb(34, 34, 34); */
  padding: 8px 0;
  color: white;
  border: 1px solid rgb(28, 85, 95);
}

.productInfoPriceCartBtn button img {
  margin-right: 8px;
  width: 18px;
}
.loader {
  width: 35px;
}

/* Responsive Styles */
@media screen and (max-width: 860px) {
  .productCard {
    width: 15rem;
  }
  .iconContainer {
    opacity: 1;
  }
  .productImageContainer {
    height: 250px;
  }

  .productInfo span {
    font-size: 13px;
  }

  .productInfo .productName {
    font-size: 16px;
  }

  .productInfoPrice {
    gap: 8px;
    margin: 3px 0 10px 0;
  }
  .productInfoPrice div:nth-child(1) {
    font-size: 20px;
  }
  .productInfoPrice div:nth-child(2) {
    font-size: 13px;
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 770px) {
  .productCard {
    width: 13rem;
  }
  .iconContainer button img {
    width: 22px;
    height: 16px;
  }

  .productImageContainer {
    height: 230px;
  }

  .productCardTag {
    font-size: 9px;
    padding: 1px 4px;
  }

  .productInfo {
    padding: 7px 10px;
  }

  .productInfo span {
    font-size: 12px;
  }

  .productInfoPriceCart div p {
    font-size: 15px;
  }
}

@media screen and (max-width: 670px) {
  .productCard {
    width: 20rem;
  }
  .productInfo .productName {
    font-size: 17px;
  }
  .productImageContainer {
    height: 300px;
  }
}

@media screen and (max-width: 577px) {
  .productCard {
    width: 14rem;
  }
  .productInfo .productName {
    font-size: 15px;
  }
  .productImageContainer {
    height: 260px;
  }
  .rating {
    gap: 3px;
  }
  .rating div p {
    font-size: 0.8rem;
  }
  .ratingStar img {
    width: 16px;
  }
}

@media screen and (max-width: 512px) {
  .productCard {
    margin: 5px;
    width: 13rem;
  }

  .productImageContainer {
    height: 240px;
  }
  .productInfo .productCategory {
    font-size: 10.5px;
  }
}

@media screen and (max-width: 440px) {
  .productCard {
    margin: 3px;
    width: 12rem;
  }

  .productImageContainer {
    height: 230px;
  }

  .productInfo .productName {
    font-size: 14px;
  }

  .productInfoPrice div:nth-child(1) {
    font-size: 18px;
  }
  .productInfoPrice div:nth-child(2) {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .productCard {
    margin: 3px;
    width: 11rem;
  }

  .productImageContainer {
    height: 225px;
  }

  .productInfo {
    padding: 3px 7px 10px 7px;
  }
  .productInfoPriceCartBtn button {
    font-size: 12px;
  }
  .productInfoPriceCartBtn button img {
    width: 15px;
  }
}

@media screen and (max-width: 365px) {
  .productCard {
    margin: 2px;
    width: 10rem;
  }

  .productImageContainer {
    height: 200px;
  }

  .productInfo p:nth-child(2) {
    font-size: 11px;
  }
  .productInfoPriceCart div p {
    font-size: 13px;
    color: rgb(28, 85, 95);
  }
  .productInfoPriceCartBtn button {
    font-size: 12px;
    padding: 7px 0;
  }
  .productInfoPriceCartBtn button img {
    width: 13px;
  }
}

@media screen and (max-width: 320px) {
  .productCard {
    width: 130px;
  }

  .productImage {
    height: 190px;
  }
}
