.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  border-radius: 8px;
  width: 370px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

.modalContent h2 {
  font-size: 16px;
  text-align: left;
  text-transform: none;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 14px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modalField {
  margin: 16px 0;
}

.modalField label {
  display: block;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 500;
  color: #555;
  margin-bottom: 4px;
}

.modalField input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #333;
  margin: 7px 0;
}

.trackingLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 0.9rem;
  margin: 7px 0;
  color: #007bff;
}

.trackingLink a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.trackingLink a svg {
  margin-left: 4px;
  fill: #007bff;
}
