.allproductContainer {
    text-align: center;
    padding: 35px 0;
    line-height: 1.6;
}

.heading {
    font-size: 30px;
}

.summary {
    color: grey;
    font-size: 17px;
}

.productListContainer {
    text-align: center;
    padding: 50px 0;
}

.productList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}



.viewBtn {


    padding: 10px 20px;
    margin: 30px 0;
    color: #1c5560;
    background-color: transparent;
    border: 2px solid #1c5560;
    transition: 0.2s;
    border-radius: 3px;
}

.viewBtn div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.viewBtn:hover {
    transition: 0.2s;
    background-color: #1c5560;
    color: white;
}

.viewBtn:active {
    transform: scale(0.98);
}

/* skeleton loading .....  */
.productSkeletonLoading {
    max-width: 250px;
    width: 100%;
    background-color: #ffffff;
    border-width: 1px;
    border-color: #e5e7eb;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    margin: 0 10px;
    padding: 1rem;
    border: 1px solid rgb(250, 250, 250);
}

.skeletonLoadingButton {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 780px) {
    .allproductContainer {
        padding: 15px 0;
        line-height: 1.6;
    }

    .viewBtn {
        padding: 8px 16px;
    }

    .heading {
        font-size: 22px;
    }

    .summary {
        font-size: 15px;
    }


}

/* skeleton loading .....  */
@media screen and (max-width: 560px) {

    .productSkeletonLoading {
        max-width: 220px;
        margin: 0 5px;
        padding: 10px;
    }

}

@media screen and (max-width: 475px) {

    .productSkeletonLoading {
        max-width: 180px;
        margin: 0 5px;
        padding: 10px;
    }

}

@media screen and (max-width: 400px) {
    .viewBtn {
        padding: 6px 10px;
    }
}