.ourCollections {
  padding: 50px 0;
}
.ourCollections h1 {
  text-transform: none;
  text-align: center;
  font-size: 35px;
}
.collections {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 60px;
  gap: 20px;
}
.collection {
  position: relative;
  cursor: pointer;
}
.collection img {
  border-radius: 8px;
  width: 300px;
  height: 400px;
}
.collectionContent {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 80px; /* Height of the overlay */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1000),
    transparent
  ); /* Increased opacity for a darker background */
  z-index: 1;
  padding-left: 20px;
}
.collectionContent h2 {
  font-size: 26px;
  color: white;
  font-weight: bold;
}
.collectionContent p {
  font-size: 16px;
  color: white;
}
.collection button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  bottom: 30px;
  padding: 0 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 40px;
  font-size: 16px;
  background-color: #121313;
  border-radius: 17px;
  border: none;
  outline: none;
  color: white;
  transition: background-color 0.3s ease, border 0.3s ease, color 0.3s ease; /* Add transition */
}

.collection button:hover {
  background-color: #2d7a86;
}

@media screen and (max-width: 800px) {
  .ourCollections h1 {
    font-size: 19px;
  }
  .collection img {
    width: 200px;
    height: 300px;
  }
  .collection button {
    width: 130px;
    height: 35px;
    font-size: 13px;
  }
  .collectionContent h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  .collectionContent p {
    font-size: 13px;
  }
}
@media screen and (max-width: 600px) {
  .ourCollections h1 {
    font-size: 25px;
  }
  .collections {
    padding: 35px 00px;
    gap: 10px;
  }
  .collection img {
    width: 170px;
    height: 250px;
  }
  .collection button {
    width: 110px;
    bottom: 15px;
    height: 30px;
    font-size: 12px;
  }
}
