/* FOR TOP NAVIGATION  */
.productDetailsTopContainerNavigation {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 60px;
  background-color: #f6f6f6;
}

.productDetailsTopContainerNavigation div {
  display: flex;
  align-items: center;
  color: rgb(58, 58, 58);
  justify-content: center;
  font-size: 14px;
}

.productDetailsTopContainerNavigation div a {
  color: rgb(58, 58, 58);
}

/* MAIN CONTAINER  */
.productDetailsContainerr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 0px;
}

/* IMAGE SECTION  */

.productImageContainerr {
  display: flex;
  justify-content: center;
  /* width: 1700px;  */
}

.productImageContainerrMoreColors {
  overflow: scroll;
  max-height: 600px;
}

.productImageContainerrMoreColors div img {
  width: 90px;
  height: 120px;
  margin-right: 10px;
  margin-bottom: 6px;
  border: 1px solid rgb(162, 161, 161);
  cursor: pointer;
}

.productImageContainerrMoreColors div img.activeImg {
  border: 2px solid black;
}

.productImageContainerrTopShowImg {
  width: 550px;
  height: 600px;
}

.productImageContainerrTopShowImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

/* PRODUCT SECTION  */
.productDetailss {
  width: 500px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 50px;
  line-height: 1.5;
}

.productDetailss h2 {
  text-transform: none;
  font-size: 24px;
  color: #333;
  margin-top: 15px;
}
/* rating  */
.rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-top: 10px;
}
.rating div p {
  color: #71717a;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.ratingStar {
  display: flex;
  align-items: center;
}
.ratingStar img {
  width: 20px;
}

.productDetailsInStock {
  color: #5f8666;
  background-color: #ddf8e8;
  padding: 3px 10px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
}

.productDetailsOutStock {
  color: red;
  font-weight: bold;
}

.productDetailsPrice {
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0.5px;
  margin-top: 10px;
}

.productDetailsPrice span:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.7px;
  text-decoration: line-through;
  color: #8c858c;
  margin-left: 10px;
}
.productDetailsPrice span:nth-child(2) {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.7px;
  background-color: #e9413f;
  color: white;
  padding: 6px 10px;
  border-radius: 20px;
  margin-left: 10px;
}
.includingTax {
  color: #79797d;
  font-weight: 300;
  font-size: 15px;
  margin-top: 5px;
}
.horiZontalLine {
  width: 100%;
  height: 1px;
  background-color: #d4d4d4;
  margin-top: 20px;
}
.productColor {
  /* font-size: 15px; */
}
/* description  */
.productDetailsDesc {
  padding: 20px 50px;
  color: #374151;
  line-height: 25px;
  font-size: 16px;
}

.productDetailss h3 {
  font-size: 20px;
  margin: 10px 0;
}
/*product details table  */
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 20px 50px;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
}

.label {
  font-weight: bold;
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
}

.value {
  font-size: 14px;
  color: #555;
}
/* reviews  */
.productReview {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productReview .reviewCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px 15px;
  max-width: 100%;
  width: 100%;
  background: #fff;
}

.reviewProfile {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.reviewAvatar {
  width: 40px;
  height: 40px;
  background: #eee;
  border-radius: 50%;
}

.reviewName {
  font-size: 16px;
  font-weight: bold;
}

.reviewRating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.reviewRating img {
  width: 20px;
}

.reviewComment {
  margin-top: 10px;
  font-size: 13px;
  color: #374151;
  line-height: 20px;
}

.reviewTime {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  color: gray;
}

.singleProductOptions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
  padding: 0 50px;
}

.singleProductOptions p {
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid rgb(224 224 224);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.singleProductOptions p.active {
  background-color: rgb(238 238 238);
  transition: 0.2s;
}

.quantityContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 15px;
}
.quantityUpdate {
  margin-top: 20px;
}

.quantityUpdate p {
  color: black;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
}
.quantityControl {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantityControl button {
  width: 49px;
  height: 45px;
  border: 1px solid #ccc;
  outline: none;
  background-color: white;
  font-size: 18px;
  color: #363636;
}

.quantityControl button:hover {
  background-color: #e5e5e5;
}
.heartButton {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  height: 40px;
  width: 250px;
  border-radius: 4px;
  font-size: 15px;
}
.heartButton img {
  width: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantityControl input {
  width: 50px !important;
  height: 45px;
  text-align: center;
  border-top: 1px solid #ccc !important;
  border: none;
  border-radius: 0% !important;
  border-bottom: 1px solid #ccc !important;
}

.addIconsDetaiils {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.addIconsDetaiils div:nth-child(1) {
  display: flex;
  align-items: center;
}

.purchaseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  gap: 8px;
  background-color: #1e5361;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  /* margin-left: 10px; */
}
.purchaseButton img {
  width: 17px;
}
.whatsappOrderButton {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.shareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  height: 40px;
  border-radius: 4px;
  width: 55px;
}
.shareButton img {
  width: 25px;
}
.whatsappOrderButton a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  gap: 5px;
  background-color: #51cd5d;
  color: #fff;
  font-size: 15px;
  transition: 0.2s;
  border: none;
  border-radius: 4px;
}
.whatsappOrderButton a:hover {
  transition: 0.2s;
  background-color: #70df7b;
}

.purchaseButtonLoader {
  height: 40px;
  width: 100%;
  background-color: #1e5361;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.purchaseButtonLoader img {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.purchaseButton a {
  color: #fff;
}

/* .purchaseButton a:visited {
    color: #fff;

} */

.purchaseButton:hover {
  background-color: #1e5361;
}

.productStock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.productStock div:nth-child(2) {
  color: #4e4a44;
  background-color: #f4f3f8;
  border-radius: 30px;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: bold;
}

.sellerInfo {
  width: 100%;
  border: 1px solid rgb(230, 230, 230);
  font-weight: bold;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 16px;
  color: green;
}

.sellerInfo span {
  margin-left: 5px;
}

.loader {
  width: 40px;
}

.productDetailsContainerrLoading {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 30px 50px;
}

.productDetailssLoading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 1163px) {
  .productImageContainerrTopShowImg {
    width: 450px;
  }
}

@media screen and (max-width: 1063px) {
  .productDetailss {
    padding-left: 35px;
  }

  .productImageContainerrTopShowImg {
    width: 400px;
  }
}

@media screen and (max-width: 1014px) {
  .productDetailss {
    padding: 20px 0px;
    width: 550px;
  }

  .productImageContainerrTopShowImg {
    width: 550px;
  }

  .singleProductOptions {
    justify-content: center;
    margin-top: 20px;
    padding: 0 20px;
  }
  .container {
    gap: 10px;
    padding: 20px 30px;
  }
  .productReview {
    padding: 20px 30px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .productDetailsTopContainerNavigation {
    padding-left: 35px;
    background-color: #f6f6f6;
  }
}

@media screen and (max-width: 680px) {
  .productDetailss {
    padding: 20px 0px;
    width: 550px;
  }

  .productImageContainerr {
    flex-direction: column-reverse;
  }

  .productImageContainerrTopShowImg {
    width: 550px;
  }

  .productImageContainerrMoreColors {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    flex-wrap: wrap;
  }

  .productImageContainerrMoreColors div img {
    width: 50px;
    border-radius: 50%;
    height: 50px;
  }

  .productDetailsContainerr {
    margin: 0 20px;
    padding: 20px 0 0 0;
  }
}

@media screen and (max-width: 570px) {
  .productImageContainerrTopShowImg {
    width: 500px;
    height: 719.08px;
  }

  .productDetailsTopContainerNavigation p {
    font-size: 14px;
  }

  .productDetailss h3 {
    font-size: 18px;
  }

  .productDetailss h2 {
    font-size: 22px;
  }

  .productDetailsTopContainerNavigation {
    margin: 10px 0;
  }
  .singleProductOptions {
    gap: 10px;
    padding: 0 20px;
  }
  .singleProductOptions p {
    font-size: 13px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
  .productDetailsDesc {
    padding: 20px 30px;
    font-size: 14px;
    text-align: left;
  }
  .productReview {
    padding: 20px 20px;
    align-items: center;
    justify-content: center;
  }
  .sellerInfo {
    color: green;
    font-size: 14px;
  }
  .reviewName {
    font-size: 14px;
  }
  .reviewRating img {
    width: 15px;
  }
}

@media screen and (max-width: 520px) {
  .productImageContainerrTopShowImg {
    width: 450px;
    height: 647.65px;
  }
  .productDetailsContainerrLoading {
    display: flex;
    justify-content: center;
    align-items: center;

    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 1rem;
    padding: 30px 50px;
  }
  .productDetailssLoading {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .container {
    gap: 10px;
    padding: 20px 10px;
  }
  .row {
    padding: 7px;
  }
}

@media screen and (max-width: 480px) {
  .productImageContainerrTopShowImg {
    width: 350px;
    height: 504.8px;
  }

  .productImageContainerrMoreColors div img {
    width: 40px;
  }
  .heartButton {
    gap: 5px;
    height: 40px;
    width: 250px;
    font-size: 14px;
  }
  .singleProductOptions {
    gap: 6px;
    padding: 0 10px;
  }
  .singleProductOptions p {
    padding: 6px;
    font-size: 13px;
  }
}
